import { useCallback } from 'react';
import Particles from 'react-particles';
import { loadFull } from 'tsparticles';

const colors = ['#aaaaaa', '#000000', '#000000', '#000000', '#000000', '#000000'];

export default function ParticlesWrapper() {
    const particlesInit = useCallback(async (engine) => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {}, []);

    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    opacity: 0,
                },
                fpsLimit: 60,
                particles: {
                    rotate: {
                        random: {
                            enable: true,
                        },
                        value: 0,
                        animation: {
                            enable: true,
                            speed: 5,
                            dacay: 0,
                            sync: false,
                        },
                        direction: 'random',
                    },
                    color: {
                        value: colors,
                    },
                    collisions: {
                        enable: true,
                    },
                    move: {
                        enable: true,
                        directions: 'random',
                        outModes: {
                            default: 'out',
                        },
                        random: false,
                        speed: 1,
                        trail: {
                            enable: true,
                            length: 100,
                            fill: {
                                color: colors,
                            },
                        },
                        spin: {
                            enable: true,
                        },
                    },
                    number: {
                        value: 15,
                    },
                    shape: {
                        type: 'triangle',
                    },
                    size: {
                        value: { min: 200, max: 700 },
                    },
                },
                detectRetina: true,
            }}
        />
    );
}
