import { useEffect } from 'react';

import twan from '../assets/twon.JPG';

import './Home.css';
import Listen from '../Listen';
import Watch from '../Watch';
import ParticlesWrapper from '../Particles';

export default function Home() {
    useEffect(() => {
        document.body.classList.toggle('home-page', true);

        return () => {
            document.body.classList.toggle('home-page', false);
        };
    }, []);

    return (
        <main className="bg-black min-h-screen">
            <ParticlesWrapper />
            <div
                style={{
                    backgroundImage: 'url(https://grainy-gradients.vercel.app/noise.svg)',
                }}
                className="fixed top-0 right-0 left-0 bottom-0 z-10 pointer-events-none bg-center bg-repeat mix-blend-hard-light opacity-30 md:opacity-20"
            ></div>
            <div className="max-w-2xl relative mx-auto py-16 md:py-32 px-6">
                <div className="flex flex-col items-center">
                    <div className="mb-2">
                        <img src={twan} className="w-24 h-24 rounded-full" alt="" />
                    </div>
                    <div>
                        <p className="text-sm leading-3">DJ</p>
                        <h1 className="font-black text-3xl md:text-5xl">FIRETOWER</h1>
                    </div>
                </div>
                <h2 className="uppercase mt-12 font-black text-xl md:text-2xl">Listen</h2>
                <Listen />
                <h2 className="uppercase mt-12 mb-4 font-black text-xl md:text-2xl">Watch</h2>
                <Watch />
                {/* FOOTER */}
                <div className="mt-16 italic uppercase opacity-50 flex flex-col md:flex-row items-center gap-2 justify-center">
                    <a
                        className="transition-opacity hover:opacity-50"
                        href="https://www.instagram.com/firetower.dj/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Instagram
                    </a>
                    <p className="hidden md:block">·</p>
                    <a
                        className="transition-opacity hover:opacity-50"
                        href="https://soundcloud.com/djfiretower"
                        target="_blank"
                        rel="noreferrer"
                    >
                        SoundCloud
                    </a>
                    <p className="hidden md:block">·</p>
                    <p>twan.mulder[at]gmail.com</p>
                </div>
            </div>
        </main>
    );
}
