export default function YoutubeEmbed(props) {
    const { title, embedID } = props;

    return (
        <div className="video-responsive">
            <iframe
                className="w-full aspect-video relative z-10"
                title={title}
                src={`https://www.youtube.com/embed/${embedID}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </div>
    );
}
