import { useEffect, useState } from 'react';
import Collapse from './Collapse';
import Airtable from 'airtable';

export default function Listen() {
    const [links, setLinks] = useState([]);

    useEffect(() => {
        let base = new Airtable({
            apiKey: 'patGIGJ3Vm1GkAVcE.b24cb4a556a9bb31db3920d55de824262903515ede7eb94c192a19674787e757',
        }).base('appdzaFWoQ7PdmUGm');
        let cleanedData = [];

        base('Listen')
            .select({
                maxRecords: 100,
                view: 'Grid view',
            })
            .eachPage(
                function page(records, fetchNextPage) {
                    records.forEach(function (record) {
                        const { ButtonText, Destination, Name, URL } = record.fields;
                        cleanedData.push({
                            name: Name,
                            url: URL,
                            destination: Destination,
                            buttonText: ButtonText,
                        });
                    });

                    fetchNextPage();
                },
                function done(err) {
                    setLinks(cleanedData);
                    if (err) {
                        console.error(err);
                        return;
                    }
                }
            );
    }, []);

    return links.map((link) => {
        const { destination, url, buttonText } = link;
        if (destination === 'External') {
            return (
                <a href={url} className="external-link" target="_blank" rel="noreferrer">
                    <span className="">{buttonText}</span>
                </a>
            );
        }

        return <Collapse key={url} buttonText={buttonText} url={url} />;
    });
}
