/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import { useState, useEffect } from 'react';
import Hydra from 'hydra-synth';

import './Visuals.css';

let initHydra = false;

export default function Visuals() {
    const [cutoff, setCutoff] = useState(2.5);

    useEffect(() => {
        document.body.classList.toggle('visuals-page', true);

        return () => {
            document.body.classList.toggle('visuals-page', false);
        };
    }, []);

    useEffect(() => {
        if (!initHydra) {
            initHydra = new Hydra({
                width: window.innerWidth,
                height: window.innerHeight,
                detectAudio: true,
            });
        }

        a.setBins(8);
        a.setSmooth(0.9);
        a.setCutoff(cutoff);

        src(o0)
            .modulateHue(src(o0).scale(1.01), 2)
            .layer(osc(8, 0.1, () => a.fft[0] * 10).mask(shape(4, 0.3, 1e-6)), 0.1)
            .out();
    }, [cutoff]);

    return (
        <div>
            <p>TWÆN</p>
            <aside>
                {/* <input
                    type="range"
                    id="cutoff"
                    name="cutoff"
                    min="0"
                    max="10"
                    value={cutoff}
                    step="0.5"
                    onChange={(e) => setCutoff(e.target.value)}
                />
                <label for="cutoff">Cutoff: {cutoff}/10</label> */}
            </aside>
        </div>
    );
}
