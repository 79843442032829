import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import ReactPlayer from 'react-player';

import ExternalLinkIcon from './assets/ExternalLinkIcon';

import './Collapse.css';

export default function Collapse(props) {
    const { buttonText, url } = props;
    const [isActive, setIsActive] = useState(false);
    const [renderContent, setRenderContent] = useState(false);

    const handleTitleClick = () => {
        setRenderContent(true);
        setIsActive(!isActive);
    };

    return (
        <div className="collapse-wrapper">
            <button className="collapse-button" onClick={handleTitleClick}>
                {buttonText}
            </button>
            <div className="collapse-content">
                <AnimateHeight
                    duration={500}
                    height={isActive ? 450 : 0}
                    onHeightAnimationEnd={() => {
                        !isActive && setRenderContent(false);
                    }}
                >
                    {renderContent && (
                        <>
                            <ReactPlayer url={url} controls={true} width="100%" />
                            <a
                                href={url}
                                className="collapse-external-link"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span>Also available to stream here</span>
                                <ExternalLinkIcon />
                            </a>
                        </>
                    )}
                </AnimateHeight>
            </div>
        </div>
    );
}
