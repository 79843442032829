import { useEffect, useState } from 'react';
import Airtable from 'airtable';
import YoutubeEmbed from './YoutubeEmbed';

export default function Watch() {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        let base = new Airtable({
            apiKey: 'patGIGJ3Vm1GkAVcE.b24cb4a556a9bb31db3920d55de824262903515ede7eb94c192a19674787e757',
        }).base('appdzaFWoQ7PdmUGm');
        let cleanedData = [];

        base('Watch')
            .select({
                maxRecords: 100,
                view: 'Grid view',
            })
            .eachPage(
                function page(records, fetchNextPage) {
                    records.forEach(function (record) {
                        const { Title, embedID } = record.fields;
                        cleanedData.push({
                            title: Title,
                            embedID: embedID,
                        });
                    });

                    fetchNextPage();
                },
                function done(err) {
                    setVideos(cleanedData);
                    if (err) {
                        console.error(err);
                        return;
                    }
                }
            );
    }, []);

    return (
        <div className="flex flex-col gap-8">
            {videos.map((video) => {
                const { title, embedID } = video;
                return <YoutubeEmbed key={embedID} title={title} embedID={embedID} />;
            })}
        </div>
    );
}
